<template>
  <div class="admin-dashboard-test">
    <h1 class="h4 text-center mb-4">Test Dashboard</h1>
    <ButtonToMenu/>
    <v-app>
      <v-card class="v-card-table">
        <TestDashboardTable
          :loading="loadingTable"
          :dashboardData="dashboardData"
          :options="{
            page: page,
            itemsPerPage: itemsPerPage,
            totalPages: totalPages,
          }"
          @openDeleteModal="openDeleteModal"
          @openEditModal="openEditModal"
          @getDataTestDashboard="getDataTestDashboard"
        />
        <DeleteUserModal
          :loading="loading"
          ref="delete-user"
          @deleteUser="deleteUserConfirm"
          />
        <CreateOrEditUser
          :loading="loading"
          ref="create-edit"
          @save="save"
        />
      </v-card>
    </v-app>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import TestDashboardTable from '@modules/AdminTestDashboard/TestDashboardTable.vue';
import DeleteUserModal from '@modules/AdminTestDashboard/DeleteUserModal.vue';
import CreateOrEditUser from '@modules/AdminTestDashboard/CreateOrEditUser.vue';

export default {
  name: 'adminTestDashboard',
  components: {
    TestDashboardTable,
    DeleteUserModal,
    CreateOrEditUser,
  },
  data: () => ({
    page: 1,
    totalPages: 0,
    itemsPerPage: 0,
    searchTestDashboard: {},
    dashboardData: [],
    loadingTable: true,
    loading: false,
  }),
  async mounted() {
    await this.getDataTestDashboard({ page: 1, searchTestDashboard: { withSearch: false } });
  },
  methods: {
    openEditModal(item) {
      this.$refs['create-edit'].open(item);
    },
    async getDataTestDashboard(options) {
      this.loadingTable = true;
      this.dashboardData = [];
      if (options) {
        this.page = options.page;
        this.searchTestDashboard = options.searchTestDashboard;
      }
      const result = await this.$api.admin.getDataTestDashboard(
        process.env.QUESTIONNAIRE_ID,
        this.page - 1,
        this.searchTestDashboard,
      );
      this.setTableData(result);
      this.loadingTable = false;
    },
    setTableData(response) {
      this.page = response.number + 1;
      this.itemsPerPage = response.numberOfElements;
      this.totalPages = response.totalPages;

      if ((response.content || []).length === 0) return;

      response.content.forEach((x) => {
        const resultRole = this.USER_ROLES.map(role => x.roles.map((y) => {
          if (role.value === y) {
            return role.label;
          }
          return null;
        })).filter(role => role[0] != null);
        this.dashboardData.push({
          id: x.id,
          name: x.name,
          lastName: x.lastName,
          yourEmail: x.email,
          diaCode: x.codeCountry,
          isoCode: x.isoCountryCode,
          roles: resultRole,
          selectRole: { value: x.roles[0], label: resultRole[0][0] },
          chartTestDashboard: x.chartTestDashboard,
        });
      });
    },
    async deleteUser(userId) {
      this.loading = true;
      try {
        await this.$api.admin.deleteUserById(process.env.QUESTIONNAIRE_ID, userId);
        this.$refs['delete-user'].close();
        await this.getDataTestDashboard();
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    openDeleteModal(item) {
      this.$refs['delete-user'].open(item.id);
    },
    deleteUserConfirm(userId) {
      this.loadingTable = true;
      this.dashboardData = [];
      this.deleteUser(userId);
    },
    async save(userChangeForm) {
      this.loading = true;
      try {
        await this.$api.admin[userChangeForm.id ? 'updateUserById' : 'createUser'](
          process.env.QUESTIONNAIRE_ID,
          this.prepareDataForRequest(userChangeForm),
        );
        this.$refs['create-edit'].close();
        this.loadingTable = true;
        this.getDataTestDashboard();
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    prepareDataForRequest(userChangeForm) {
      return {
        id: userChangeForm.id,
        name: userChangeForm.name,
        lastName: userChangeForm.lastName,
        youEmail: userChangeForm.yourEmail.toLowerCase(),
        password: userChangeForm.password,
        role: userChangeForm.selectRole.value,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.v-card-table {
  margin: 20px;
}
</style>
