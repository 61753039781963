<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <!-- <v-row>
            <v-col cols="12" sm="6" md="9">
              <InputTel
                v-model="userChangeForm.phone"
                :diaCode="userChangeForm.diaCode"
                :validPhone="$v.userChangeForm.phone"
                @onDiaCode="countryChanged"
                :placeHolder="configEnv.testdashboard.placeholderPhoneDashboard"
                :defaultCountry="getDefaultState"
              />
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="First Name"
                hide-details
                v-model="userChangeForm.name"
              />
              <template v-if="$v.userChangeForm.name.$error">
                <div
                  class="form__input-error"
                  v-if="!$v.userChangeForm.name.required"
                >
                  Field is required
                </div>
              </template>
            </v-col>
            <!-- <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Last Name"
                v-model="userChangeForm.lastName"
                hide-details
              />
              <template v-if="$v.userChangeForm.lastName.$error">
                <div
                  class="form__input-error"
                  v-if="!$v.userChangeForm.lastName.required"
                >
                  Field is required
                </div>
              </template>
            </v-col> -->
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Your email"
                v-model="userChangeForm.yourEmail"
                hide-details
              />
              <template v-if="$v.userChangeForm.yourEmail.$error">
                <div class="form__input-error">
                  <span v-if="!$v.userChangeForm.yourEmail.required">Field is required</span>
                  <span v-else-if="!$v.userChangeForm.yourEmail.mustBeCool">
                    Check correct email</span>
                </div>
              </template>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Password"
                v-model="userChangeForm.password"
                hide-details
                type="password"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="this.USER_ROLES"
                item-text="label"
                item-value="value"
                :label="roleLable"
                v-model="userChangeForm.selectRole"
                return-object
                @change="(answers) => editRole(answers)"
                hide-details
              ></v-select>
              <template v-if="$v.userChangeForm.selectRole.$error">
                <div
                  class="form__input-error"
                  v-if="!$v.userChangeForm.selectRole.roleValidate"
                >
                  Field is required
                </div>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close" :disabled="loading">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="save" :disabled="loading">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import configEnv from '@configEnv/index';
import { validationMixin } from 'vuelidate';
import helpFunction from '@helpers/helpFunction';

const {
  required,
} = require('vuelidate/lib/validators');

const roleValidate = (role) => {
  if (!(role && role.label && role.value)) {
    return false;
  }
  return true;
};

export default {
  data() {
    return {
      configEnv,
      dialog: false,
      userId: null,
      userChangeForm: {
        id: '',
        // phone: '',
        name: '',
        // lastName: '',
        yourEmail: '',
        diaCode: '',
        isoCountryCode: '',
        password: '',
        selectRole: [],
      },
      defaultUserChangeForm: {
        id: '',
        // phone: '',
        name: '',
        // lastName: '',
        yourEmail: '',
        diaCode: '',
        isoCountryCode: '',
        password: '',
        selectRole: [],
      },
      formTitle: 'New Item',
      roleLable: 'New Role',
    };
  },
  validations: {
    userChangeForm: {
      // phone: {
      //   required,
      // },
      name: {
        required,
      },
      yourEmail: {
        required,
        mustBeCool: helpFunction.mustBeCool,
      },
      // lastName: {
      //   required,
      // },
      selectRole: {
        roleValidate,
      },
    },
  },
  mixins: [validationMixin],
  props: {
    loading: {
      type: Boolean,
    },
  },
  computed: {
    // getDefaultState() {
    //   return configEnv.testdashboard.defaultStatePhone;
    // },
  },
  methods: {
    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('save', this.userChangeForm);
      }
    },
    close() {
      this.userChangeForm = Object.assign({}, this.defaultUserChangeForm);
      this.$v.$reset();
      this.dialog = false;
    },
    open(item) {
      this.formTitle = item ? 'Edit Item' : 'New Item';
      this.roleLable = item ? 'Role' : 'New Role';
      this.userChangeForm = Object.assign({}, item);
      // if (this.userChangeForm.phone === '') {
      //   this.userChangeForm.isoCountryCode = configEnv.testdashboard.defaultStatePhone;
      //   this.userChangeForm.phone = configEnv.testdashboard.defaultCodePhone;
      // } else {
      //   this.userChangeForm.isoCountryCode = item.isoCode;
      // }
      this.dialog = true;
    },
    // countryChanged(data) {
    //   this.userChangeForm.isoCountryCode = data.iso2;
    //   this.userChangeForm.diaCode = data.dialCode;
    // },
    editRole(e) {
      this.userChangeForm.role = e.value;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .form__input-tel {
  .vti__country-code {
    display: none;
  }
  .vti__input {
    padding-left: 20px !important;
  }
}
::v-deep .form__input-error {
  padding-left: 0;
}
</style>
